import { Button, Col, Container, Row } from "reactstrap";
import Link from "next/link";
import Image from "next/image";

//local imports
import classes from "@/styles/generic/about.module.css";
import { about } from "@/utils/content";
import { aboutImg1, shopifyRating, wooComRating } from "@/utils/images";
import { routes } from "@/utils/routes";
import { useIsMobile } from "@/hooks/useIsMobile";

export default function About() {
  const isMobile = useIsMobile();

  return (
    <>
      <div className={`${classes.container}`}>
        <Container>
          {isMobile ? (
            <Row>
              <Col className={classes.sec2Container} xs={12} lg={6}>
                <Image
                  src={aboutImg1}
                  alt={aboutImg1}
                  height={550}
                  width={550}
                  priority
                  className={classes.img1}
                  placeholder="blur"
                  blurDataURL={aboutImg1}
                />
                <div className={classes.sec2Float}>
                  <h3 className={classes.sec2Title}>{about.en.sec2Title}</h3>
                  <h2 className={classes.sec2Heading}>
                    {about.en.sec2Heading}
                  </h2>
                  <Link
                    className={classes.sec2Link}
                    href={routes.openAccountOffer}
                  >
                    {about.en.sec2Link}
                  </Link>
                  <Row>
                    <Col className="text-end" xs={6}>
                      <Image
                        src={shopifyRating}
                        alt={shopifyRating}
                        height={70}
                        width={150}
                        className={classes.gr2ColImg}
                        placeholder="blur"
                        blurDataURL={aboutImg1}
                      />
                    </Col>
                    <Col className="text-start" xs={6}>
                      <Image
                        src={wooComRating}
                        alt={wooComRating}
                        height={70}
                        width={150}
                        className={classes.gr2ColImg}
                        placeholder="blur"
                        blurDataURL={aboutImg1}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className={classes.sec1Container} xs={12} lg={6}>
                <h1 className={`${classes.heading} text-center`}>
                  {about.en.heading}
                </h1>
                <div
                  className={classes.para1}
                  dangerouslySetInnerHTML={{
                    __html: about.en.para1,
                  }}
                ></div>
                <div
                  className={classes.para2}
                  dangerouslySetInnerHTML={{
                    __html: about.en.para2,
                  }}
                ></div>
                <Link
                  href={routes.register}
                  className={`btn btn-secondary ${classes.ctaBtn}`}
                >
                  {about.en.ctaText}
                </Link>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className={classes.sec1Container} xs={12} lg={6}>
                <h1 className={`${classes.heading} text-center`}>
                  {about.en.heading}
                </h1>
                <div
                  className={classes.para1}
                  dangerouslySetInnerHTML={{
                    __html: about.en.para1,
                  }}
                ></div>
                <div
                  className={classes.para2}
                  dangerouslySetInnerHTML={{
                    __html: about.en.para2,
                  }}
                ></div>
                <Link
                  href={routes.register}
                  className={`btn btn-secondary ${classes.ctaBtn}`}
                >
                  {about.en.ctaText}
                </Link>
              </Col>
              <Col className={classes.sec2Container} xs={6}>
                <Image
                  src={aboutImg1}
                  alt={aboutImg1}
                  height={550}
                  width={550}
                  priority
                  className={classes.img1}
                />
                <div className={classes.sec2Float}>
                  <h3 className={classes.sec2Title}>{about.en.sec2Title}</h3>
                  <h2 className={classes.sec2Heading}>
                    {about.en.sec2Heading}
                  </h2>
                  <Link
                    className={classes.sec2Link}
                    href={routes.openAccountOffer}
                  >
                    {about.en.sec2Link}
                  </Link>
                  <Row>
                    <Col className="text-end" xs={6}>
                      <Image
                        src={shopifyRating}
                        alt={shopifyRating}
                        height={70}
                        width={150}
                        className={classes.gr2ColImg}
                      />
                    </Col>
                    <Col className="text-start" xs={6}>
                      <Image
                        src={wooComRating}
                        alt={wooComRating}
                        height={70}
                        width={150}
                        className={classes.gr2ColImg}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <div className={classes.bottomShape} />
    </>
  );
}
